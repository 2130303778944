<template>
  <div class="el-table-con">
    <el-table
      ref="refTable"
      v-loading="loading"
      :data="list"
      :border="border"
      :row-key="getRowKey"
      :reserve-selection="true"
      :tree-props="treeProps"
      fit
      highlight-current-row
      style="width:100%"
      @select-all="handleSelectAll"
      @selection-change="handleSelectionChange"
      @sort-change="handleSort">
      <!-- 特殊列 -->
      <slot v-if="columns.length" name="special"/>
      <!-- 列数据展示 -->
      <template v-for="column in columns">
        <template v-if="!column.special">
          <el-table-column
            :prop="column.prop"
            :label="t(column.label)"
            :key="column.prop"
            :sortable="!column.sortable ? false : column.sortable"
            :formatter="column.formatter"
            :min-width="column.width ? column.width : 120"
            :show-overflow-tooltip="true"
            :align="column.align ? column.align : 'center'"/>
        </template>
        <slot v-else :name="column.prop"/>
      </template>
      <!-- 操作插槽 -->
      <slot v-if="columns.length" name="operate"/>
      <template #empty>
        <el-empty :description="text" />
      </template>
    </el-table>
  </div>
  <div v-if="total > 0" class="pagination-con pt-6">
    <el-pagination
      class="justify-center"
      v-model:currentPage="currentPage"
      v-model:page-size="limit"
      :page-sizes="pageSizes"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"/>
  </div>
</template>

<script setup lang="ts">
import { tableProps } from './props'
import { computed, onMounted, ref } from 'vue'
import { ElTable } from "element-plus";
import { useI18n } from "vue-i18n";

const { t }  = useI18n();
const props = defineProps(tableProps)
const emits = defineEmits(['initFn', 'getList', 'clearSelection','handleSort','clearSelection', 'update:page-num', 'update:page-size','handleSelectionChange', 'handleSelectAll'])

const currentPage = computed({
  get: () => props.pageNum,
  set: val => {
    emits('update:page-num', val)
  }
})
const limit = computed({
  get: () => props.pageSize,
  set: val => {
    emits('update:page-size', val)
  }
})
const refTable = ref(null);

const handleSort = (e) => {
  emits('handleSort', e.order)
}
const handleSizeChange = (val:number) => {
  currentPage.value = 1;
  emits('getList')
};
const handleCurrentChange=(val:number) => {
  emits('getList')
}
const handleSelectionChange =(val:object)=>{
  emits('handleSelectionChange',val)
}
const handleClearSelectionChange=()=>{
    refTable.value.clearSelection();
}
const checkedItem = (row: any) => {
  refTable.value.toggleRowSelection(row, true)
}
const getRowKey = (row: any) => {
    return row.id
}
const handleSelectAll = (selection: any[]) => {
  emits('handleSelectAll', selection)
}
onMounted(() => {
  emits('initFn')
  emits('getList')
})
defineExpose({
	handleClearSelectionChange,
  checkedItem
})

</script>

<style lang="postcss" scoped>
.el-table-con{
    .el-table{
      border-bottom: 0;
    }
    :deep(.el-table tr){
        height: 52px;
    }
    :deep(.row-style:hover),:deep(.current-row>td.el-table__cell){
      background: #F9FAFC ;
    }
    :deep(.el-table .cell){
      padding: 0 15px;
      text-overflow: clip;
      word-break: normal;
    }
    .el-table--border{
      border-right: 0 !important;
    }
    :deep(.el-table th.el-table__cell){
      background-color:#ffffff;
      font-weight:500;
      color: #757B8C;
      height: 52px;
      font-size: 14px;
    }
    :deep(.el-table .el-table__cell){
      padding: 4px 0;
      height: 40px;
      color: #262E44;
      font-size: 14px;
      z-index: unset;
    }

    /* :deep(.el-scrollbar__bar) {
      display: none !important;
    } */
  }
</style>
