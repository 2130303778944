import axios from '/@/utils/request';

const api = {
  getChatRecord: '/base/comm/getChatRecord',
  jumpChatRecord: '/base/comm/jumpMsg',
  searchSocial: '/base/comm/searchSocial',
  searchFans: '/base/comm/searchFans',
  getChatMsg: '/base/comm/getChatMsg',
  openSearchMsg: '/base/comm/openSearchMsg',
  getInvList: '/base/comm/invitationByList',
  getFansInfo: '/base/comm/getFansInfo',
  setFansLabel: '/base/comm/setFansLabel',
  setFansRemark: '/base/comm/setFansRemark',
  addFansTrackInfo: '/base/comm/addFansTrackInfo',
  delFansTrackInfo: '/base/comm/delFansTrackInfo',
  saveFansRemarkName: '/base/comm/saveFansRemarkName',
  getFansLabel: '/base/comm/getAgentFansLabel',
  getFansRemarkNameList: '/base/comm/getFansRemarkNameList',
  translate: '/base/comm/translate',
  getTranslateLang: '/base/open/getTranslateLang',
  getTranslatePlatform: '/base/comm/translationTypeList',
  getAgentTranslateSettings: '/base/comm/getAgentTranslateSettings',
  setAgentTranslateSettings: '/base/comm/setAgentTranslateSettings',
  downloadMsg: '/base/comm/downloadMsg',
  sensitiveSave: '/base/comm/sensitiveSave',
  getSensitiveList: '/base/comm/getSensitiveList',
  getSensitiveDetail: '/base/comm/getSensitiveDetail',
  delSensitive: '/base/comm/delSensitive',
  delSensitiveDetail: '/base/comm/delSensitiveDetail',
  statusSensitive: '/base/comm/statusSensitive',
  getSensitiveBehavior: '/base/comm/getSensitiveBehavior',
  getBehaviorDetail: '/base/comm/getBehaviorDetail',
  delSensitiveBehavior: '/base/comm/delSensitiveBehavior',
};

export const getInvList = () => {
  return axios({
    url: api.getInvList,
    method: 'get',
    loading: false
  });
};

export const getChatRecord = (params: any) => {
  return axios({
    url: api.getChatRecord,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const jumpChatRecord = (params: any) => {
  return axios({
    url: api.jumpChatRecord,
    method: 'post',
    data: params,
  });
};

export const searchSocial = (params: any) => {
  return axios({
    url: api.searchSocial,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const searchFans = (params: any) => {
  return axios({
    url: api.searchFans,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const getChatMsg = (params: any) => {
  return axios({
    url: api.getChatMsg,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const openSearchMsg = (params: any) => {
  return axios({
    url: api.openSearchMsg,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const getFansInfo = (params: any) => {
  return axios({
    url: api.getFansInfo,
    method: 'get',
    params,
    loading: false,
  });
};

export const setFansLabel = (params: any) => {
  return axios({
    url: api.setFansLabel,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const setFansRemark = (params: any) => {
  return axios({
    url: api.setFansRemark,
    method: 'post',
    data: params,
    loading: false,
  });
};
export const addFansTrackInfo = (params: any) => {
  return axios({
    url: api.addFansTrackInfo,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const delFansTrackInfo = (params: any) => {
  return axios({
    url: api.delFansTrackInfo,
    method: 'delete',
    data: params,
    loading: false,
  });
};
export const saveFansRemarkName = (params: any) => {
  return axios({
    url: api.saveFansRemarkName,
    method: 'post',
    data: params,
    loading: false,
  });
};
export const getFansLabel = (params: any) => {
  return axios({
    url: api.getFansLabel,
    method: 'post',
    data: params,
    loading: false,
  });
};
export const getFansRemarkNameList = (params: any) => {
  return axios({
    url: api.getFansRemarkNameList,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const translate = (params: any) => {
  return axios({
    url: api.translate,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const getAgentTranslateSettings = (params: any) => {
  return axios({
    url: api.getAgentTranslateSettings,
    method: 'get',
    params,
    loading: false,
  });
};

export const setAgentTranslateSettings = (params: any) => {
  return axios({
    url: api.setAgentTranslateSettings,
    method: 'post',
    data: params,
    loading: false,
  });
};

export const downloadMsg = (params: any) => {
  return axios({
    url: api.downloadMsg,
    method: 'post',
    data: params,
    loading: false,
    responseType: 'blob',
  });
};


export const getTranslateLang = (params: any) => {
  return axios({
    url: api.getTranslateLang,
    method: 'get',
    params,
    loading: false,
  });
};

export const getTranslatePlatform = (params: any) => {
  return axios({
    url: api.getTranslatePlatform,
    method: 'get',
    params,
    loading: false,
  });
};

export const getSensitiveList = (params: any) => {
  return axios({
    url: api.getSensitiveList,
    method: 'get',
    params,
  });
};

export const getSensitiveDetail = (params: any) => {
  return axios({
    url: api.getSensitiveDetail,
    method: 'get',
    params,
  });
};

export const statusSensitive = (params: any) => {
  return axios({
    url: api.statusSensitive,
    method: 'post',
    data: params,
  });
};

export const sensitiveSave = (params: any) => {
  return axios({
    url: api.sensitiveSave,
    method: 'post',
    data: params,
  });
};


export const delSensitive = (params: any) => {
  return axios({
    url: api.delSensitive,
    method: 'delete',
    data: params,
  });
};

export const delSensitiveDetail = (params: any) => {
  return axios({
    url: api.delSensitiveDetail,
    method: 'delete',
    data: params,
  });
};

export const getSensitiveBehavior = (params: any) => {
  return axios({
    url: api.getSensitiveBehavior,
    method: 'get',
    params,
  });
};

export const getBehaviorDetail = (params: any) => {
  return axios({
    url: api.getBehaviorDetail,
    method: 'get',
    params,
  });
};

export const delSensitiveBehavior = (params: any) => {
  return axios({
    url: api.delSensitiveBehavior,
    method: 'delete',
    data: params,
  });
};



