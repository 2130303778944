/**
 * 国际化 入口
 * @author
 */

import { createI18n } from "vue-i18n";
import { getLocale, setLocale,importAllLocales, defaultLang } from "/@/utils/i18n";

/**
 * elementUI 多语言 配置
 */
import enUS from 'element-plus/lib/locale/lang/en';
import zhCN from 'element-plus/lib/locale/lang/zh-cn';

export const elementPlusMessages: { [key: string]: any} = {
    'zh-CN': zhCN,
    'en-US': enUS,
}

const sysLocale = getLocale();

/**
 * 框架 多语言 配置
 */
export const messages = importAllLocales();

export const i18n = createI18n({
    legacy: false,
    locale: sysLocale?sysLocale:(elementPlusMessages[sysLocale] ?  sysLocale: defaultLang),
    messages
});


/**
 * 设置语言
 * @param locale
 */
export function setI18nLanguage(locale: string, realReload = false): void {
    setLocale(locale,realReload, function() {
        i18n.global.locale.value = locale;
    })
}

export default i18n;
