export default {
  behavior1: '敏感行为监控',
  behavior2: '批量删除',
  behavior3: '全部',
  behavior4: '邀请码',
  behavior5: '社交平台',
  behavior6: '社交账号',
  behavior7: '请输入',
  behavior8: '请选择',
  behavior9: '删除消息',
  behavior10: '编辑消息',
  behavior11: '删除对话',
  behavior12: '操作类型',
  behavior13: '操作账号',
  behavior14: '粉丝账号',
  behavior15: '删除内容',
  behavior16: '编辑次数',
  behavior17: '操作',
  behavior18: '操作时间',
  behavior19: '操作类型',
  behavior20: '发送消息',
  behavior21: '接收消息',
  behavior22: '[图片]',
  behavior23: '[视频]',
  behavior24: '[音频]',
  behavior25: '[文件]',
  behavior26: '[名片]',
  behavior27: '[其它]',
  behavior28: '编辑记录',
  behavior29: '编辑前内容',
  behavior30: '编辑后内容',
  behavior31: '编辑时间',
  behavior32: '第{num}次',
  behavior33: '请选择要删除的敏感行为',
  behavior34:'提示',
  behavior35: '确认要删除？',
  behavior36: '确认',
  behavior37: '取消',
  behavior38: '删除成功',
  behavior39: '删除失败',
};
