export default {
  sensitive1: 'sensitive word monitoring',
  sensitive2: 'Edit',
  sensitive3: 'Details',
  sensitive4: 'Delete',
  sensitive5: 'Monitor status',
  sensitive6: 'sensitive words',
  sensitive7: 'Trigger the number of times',
  sensitive8: 'Whether to send',
  sensitive9: 'Modification success',
  sensitive10: 'Modification failed',
  sensitive11: 'Forbidden sending',
  sensitive12: 'Allow sending',
  sensitive13: 'Batch delete',
  sensitive14: 'Add sensitive words',
  sensitive15: 'Delete sensitive words',
  sensitive16: 'Reminder',
  sensitive17: 'Confirm that delete?',
  sensitive18: 'Confirm',
  sensitive19: 'Cancel',
  sensitive20: 'Please select sensitive words to delete',
  sensitive21: 'All',
  sensitive22: 'Startup',
  sensitive23: 'Not enabled',
  sensitive24: 'sensitive words',
  sensitive25: 'Add sensitive words',
  sensitive26: 'Edit sensitive words',
  sensitive27: 'Save success',
  sensitive28: 'Save failed',
  sensitive29: 'sensitive word monitoring function description',
  sensitive30:
    '1.After enabling the sensitive word monitoring function, sensitive words triggered by social account chat content will be automatically recorded in the background.',
  sensitive31:
    '2.Sensitive word messages that are prohibited from being sent cannot be sent; sensitive word messages that are allowed to be sent can be sent, but are recorded in the background.',
  sensitive32: 'Please select the sensitive words to delete the details',
  sensitive33: 'Jump to chat record',
  sensitive34:
    'Background real -time monitoring the client sends the content of the client contains sensitive words, and can set a prohibited sending sensitive words',
  sensitive35: 'sensitive word monitoring details',
  sensitive36: 'Invitation code',
  sensitive37: 'Social Platform',
  sensitive38: 'Send time',
  sensitive39: 'Invitation code',
  sensitive40: 'Social Platform',
  sensitive41: 'Social Account',
  sensitive42: 'Receive object',
  sensitive43: 'Send content',
  sensitive44: 'Jump to chat record',
  sensitive45: '3.Sensitive word detection can be triggered by directly sending or translated content.。',
};
