export default {
  arrearsReminder: {
    components1: '尊敬的%channelName%SCRM客户，您的账号即将到期！',
    components2: '%channelName%提醒您及时续费，避免造成不必要的损失！',
    components3: '天',
    components4: '小时',
    components5: '我已知晓，立即续费',
    components6:
      '---------------------------- 剩余 ----------------------------',
    components7: '我已知晓，立即联系客服续费',
  },
  navbar: {
    components1: '修改密码',
    components2: '退出登录',
    components3: '下载客户端',
    components4: '会话端口：',
    components5: '到期日期：',
    components6: '剩余时间：',
    components7: '已到期，请及时续费',
    components8: '联系客服',
    components9: '充值套餐',
    components10: '帮助',
    components11: '天',
    components12: '剩余字符量',
    components13: '套餐类型：',
    components14: '即将到期',
    components15: '已过期',
    components16: '套餐过期后, {combo}{port}个端口{day}天立即生效',
    components17: '购买套餐',
    components18: '续费',
    components19: '加端口',
    components20: '更换套餐',
    components21: '免费版',
    components22: '专业版',
    components23: '旗舰版',
    components24: '字符版',
    components25: '字符版Pro',
  },
  combo: {
    components1: '权益',
    components2: '单价',
    components3: '购买端口数',
    components4: '购买数量',
    components5: 'U/端口/月 (1月等于30天)',
    components6: '折',
    components7: '天',
    components8: 'U/端口/月',
    components9: '字符',
    components10: '不限账号不限端口',
    components11: '到期时间',
    components12: '字符数量',
    components13: '新增旗舰版{num}个端口{day}天',
    components14: '支付平台',
    components15: '支付金额',
    components16: '立即支付',
    components17: '端口数由{num1}增加至{num}个',
    components18: '明细',
    components19: '合计明细',
    components20: '折扣金额',
    components21: '抵扣金额',
    components22: '合计实付',
    components23: '支付成功后，2分钟内套餐充值成功，请耐心等待',
    components24: '确定',
    components25: '购买失败',
    components26: '补充端口数',
    components27: '订单编号：',
    components28: '支付流水号：',
    components29: '订单有效期：',
    components30: '应付金额',
    components31: '到账金额需要与显示金额一致，否则系统无法确认',
    components32: '尝试点击钱包地址或金额可直接复制',
    components33: '当前USDT支付区块网络协议为TRC20',
    components34: '已过期',
    components35: '复制成功',
    components36: '复制失败',
    components37: '支付订单',
  },
};
