export default {
  'en-US': 'English',
  'zh-CN': 'Chinese (Simplified)',
  '计数器管理后台': '%channelName% Translation/Counter Management Backend',
  common1: 'Change Password',
  common2: 'Sign out',
  common3: 'Download APP',
  common4: 'Ports:',
  common5: 'Date of Expiry:',
  common6: 'Remaining Time Until Expiration',
  common7: 'Expired, please renew in time',
  common8: 'Contact Us',
  common9: 'Recharge',
  common10: 'User Guide',
  common11: 'All status',
  common12: 'Active',
  common13: 'Disable',
  common14: 'Youdao translation',
  common15: 'Google Translate',
  common16: 'Baidu translator',
  common17: 'Customize',
  common18: 'Unlimited',
  common19: 'Day',
  common20: 'Prompt',
  common21: 'Confirm',
  common22: 'Cancel',
  common23: 'Request failed',
  common24: 'Login expired, please log in again',
  common25: 'Authentication failed',
  common26: 'No data',
  common27: 'Normal mode',
  common28: 'Extreme speed mode',
  common29: 'Share',
  common30: 'Do Not Share',
  common31: 'Open archive',
  common32: 'Close archive',
  common33: 'Operation failed',
};
