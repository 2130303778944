export const dialogProps = {
  title: String,
  illustrate: String,
  show_back_btn: {
    type: Boolean,
    default: false
  },
    rowDescription: {
        type: Boolean,
        default: false
    }
}
