export default {
  detailCode: {
    components1: 'Platform Permissions：',
    components2: 'Refresh',
    components3: 'Port limit：',
    components4: 'Number of available ports：',
    components5: 'Ports',
    components6:
      'The total number of accounts allowed to log in simultaneously under one invitation code。',
    components7:
      'For example, if you need to log in to 5 accounts at the same time, fill in 5 here',
    components8: 'Please enter the number of available ports',
    components9: 'Status：',
    components10: 'Note：',
    components11: 'Please enter content',
    components12: 'Please enter the number of available sessions',
    components13: 'Please enter an integer',
    components14: 'The minimum number of available sessions is 0',
    components15: 'Please select platform permissions',
    components16: 'Select all',
    components17: 'Prompt',
    components18: 'Confirm',
    components19: 'Cancel',
    components20:
      'When there is no need to retain fan data, it is highly recommended to use extreme speed mode to improve efficiency.',
    components21: 'Function usage：',
    components22: 'Fan Information Sharing',
    components23: 'Session archive',
    components24: 'The current package does not support this feature',
    components25: 'After turning it on, chat records within the last 60 days will be stored.',
  },
  shareCode: {
    components1: 'Invitation Code：',
    components2: 'One-Click Copy',
    components3: 'Copied successfully!',
  },
  linkCode: {
    components1: 'Active Link Name:',
    components2:
      'For example: XXX promotion (not filled in, default is date and time)',
    components3: 'Assignment rules：',
    components4: 'Designated platform',
    components5:
      'You can select this rule after checking the specified number in the number list。',
    components6: 'Check',
    components7: 'The number selected by the current invitation code',
    components8: 'Please set the account ID',
    components9: 'Choose platform：',
    components10: 'Please choose',
    components11: 'All platforms',
    components12: 'Loading...：',
    components13: 'Total',
    components14: 'Accounts can generate live links',
    components15:
      'All numbers on the selected platform under the current invitation code',
    components16: 'User binding：',
    components17: 'Turn on',
    components18: 'Close',
    components19:
      'After closing, the same device will be reassigned a new number every time it is accessed, and it is possible for one user to add multiple numbers.',
    components20:
      'After it is turned on, the numbers that meet the conditions are switched in a sequential cycle. The same device connects to the same number every time to prevent the same customer from adding multiple numbers.',
    components21: 'Binding method：',
    components22: 'IP binding',
    components23: 'Please select a platform',
    components24: 'No account can be used to generate a live link',
    components25: 'Please check the account',
    components26: 'Created successfully',
    components27: 'Data is wrong',
  },
  listCode: {
    components1: 'Refresh the list',
    components2: 'Create live link',
    components3: 'Name',
    components4: 'Copy live chain',
    components5: 'User binding',
    components6: 'Operating',
    components7: 'Remove',
    components8: 'Delete',
    components9: 'Live chain copy successful!',
    components10: 'Data is wrong',
    components11: 'Clear successfully',
    components12: 'Successfully deleted',
    components13: 'Are you sure you want to clear it?？',
    components14: 'You sure you want to delete it？',
  },
  workOrder: {
    components1: 'Fan Count Ticket Name：',
    components2: 'Please enter content',
    components3: 'Corresponding Invitation Code：',
    components4: 'Account of Fan Count Ticket:',
    components5: 'The total target of fans for each account：',
    components6: 'Maximum number of Fans per day：',
    components7: 'Turn on',
    components8: 'Close',
    components9: 'Single day follower goal for each account：',
    components10: 'Reset time of the day：',
    components11:
      'After turn on, once a single account reaches the set daily fans increase, no further fans will be assigned to that account. Additionally, at the chosen time point each day, the daily target completion count will be reset to zero.',
    components12: 'Note：',
    components13: 'No more than 50 words',
    components14: 'Fan count ticket sharing password：',
    components15:
      'Password shared by all shared links under this fan count ticket (4~8 digits)',
    components16: 'Live link user binding：',
    components17:
      'After it is turned on, the numbers that meet the conditions are switched in a sequential cycle. The same device connects to the same number every time to prevent the same customer from adding multiple numbers.',
    components18:
      'After closing, the same device will be reassigned a new number every time it is accessed, and it is possible for one user to add multiple numbers.',
    components19: 'Binding method：',
    components20: 'IP binding',
    components21: 'Please enter the sharing password',
    components22:
      'Can only contain letters and numbers, 4 to 8 characters in length',
    components23: 'Please enter the fan count ticket Name',
    components24: 'Successful operation',
  },
};